
/*
 * Filename: application/frontend/src/components/CheckoutMbWay.jsx
 * Description: CheckoutMbWay component
 * Author: Aires Marques
 * History:
 + 2023-10-22 - Checkout MbWay documented
 * Notes:
*/


import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber as validatePhoneNumber } from 'libphonenumber-js'; 
import { validateEmail, validateNif } from '../lib/validations';

// Application imports
import CheckoutMbWayModal from './CheckoutMbWayModal'; 
import { useAppState } from '../contexts/AppStateContext';
import useProduct from '../hooks/useProduct';

// Temporary imports - to be removed
// Some sort of global state management should be used instead
import { fetchLocations } from '../lib/getLocationData';


import { usePaymentProcessing } from '../hooks/useMbWayPaymentProcessing';

// Services imports
import setChatCustomAttributes  from '../services/setChatCustomAttributes';





/**
 * CheckoutMbWay component: Provides the MBWay checkout form to the user and handles
 * the payment request, polling for payment confirmation, and machine activation.
 */
function CheckoutMbWay() {
  const { locationId, productNumber } = useParams();

  // Remove this when global state management is implemented
  const [locationDefinitionData, setLocationDefinitionData] = useState(null);

  const product = useProduct(locationId, productNumber);
  const { cycles } = useAppState();
  
  // Form fields state
  const [phoneNumber, setPhoneNumber] = useState(() => {
    return localStorage.getItem('phoneNumber') || '';
  });
  
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [nif, setNif] = useState(() => {
    return localStorage.getItem('nif') || '';
  });
  const [isValidNif, setIsValidNif] = useState(false);
  
  const [email, setEmail] = useState(() => {
    return localStorage.getItem('email') || '';
  });
  const [isValidEmail, setIsValidEmail ] = useState(false);
  
  const [name, setName] = useState(() => {
    return localStorage.getItem('name') || '';
  });

  // const [ sessionUUID ] = useState(() => {
  //   return localStorage.getItem('sessionUUID') || '';
  // });


  const [showModal, setShowModal] = useState(false);

  // Process state
  const {
    paymentRequested, setPaymentRequested,
    paymentConfirmed, setPaymentConfirmed,
    paymentFailed, setPaymentFailed,
    paymentStatus, setPaymentStatus,
    machineActivated, setMachineActivated,
    submitPaymentRequest,
    pollPaymentConfirmation,
    //pollMachineActivation, //to be used later
  } = usePaymentProcessing({ locationId, productNumber, phoneNumber, nif, email, name });
  

  const navigate = useNavigate();

  // Populate productsArray based on productData and locationId
  useEffect(() => {
    const getLocationDefinitionData = async () => {
      const locations = await fetchLocations();
      const locDefinitionData = locations.find(loc => loc.laundry_id === locationId);
      if (locDefinitionData) {
        setLocationDefinitionData(locDefinitionData);
      }
    };
    getLocationDefinitionData();
  }, [locationId]);

  locationDefinitionData && locationDefinitionData.office_gest_flag === 1 
    ? console.log('OfficeGest on') 
    : console.log('OfficeGest off');

  useEffect(() => {
    localStorage.setItem('phoneNumber', phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    localStorage.setItem('nif', nif);
  }, [nif]);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem('name', name);
  }, [name]);


  useEffect(() => {
    if(phoneNumber) {
      setIsValidPhoneNumber(validatePhoneNumber(phoneNumber));
    } else {
      setIsValidPhoneNumber(false);
    }
  }, [phoneNumber]);
  
  useEffect(() => {
    if (email) {
      validateEmail(email).then(setIsValidEmail); // This will wait for the promise to resolve and then update the state
    } else {
      setIsValidEmail(false);
    }
  }, [email]);

  
  useEffect(() => {
    if(nif) {
      setIsValidNif(validateNif(nif));
    } else {
      setIsValidNif(false);
    }
  }, [nif]);
  



  useEffect(() => {
    if (paymentConfirmed) {
      setChatCustomAttributes(locationId, productNumber);
    };
    // Any other logic you want to execute when payment is confirmed
  }, [paymentConfirmed, locationId, productNumber]);


  
  /**
   * handleSubmit: Handles the form submission and initiates the payment request.
   *
   * @param {Event} e - The event object.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form...');

    // Reset the payment and machine activation states
    setPaymentRequested(false);
    setPaymentConfirmed(false);
    setPaymentFailed(false);
    setPaymentStatus(null);
    setMachineActivated(false);

    let invalidReasons = [];
    if (phoneNumber && !isValidPhoneNumber) {
      invalidReasons.push("Phone number is invalid");
    }
    if (nif && !isValidNif) {
      invalidReasons.push("NIF is invalid");
    }
    if (email && !isValidEmail) {
      invalidReasons.push("Email is invalid");
    }
  
    if (invalidReasons.length > 0) {
      console.log('Invalid form due to the following reasons:', invalidReasons.join(', '));
      return;
    }
    setShowModal(true);
  
    try {
        const paymentId = await submitPaymentRequest();
        pollPaymentConfirmation(paymentId);
    } catch (error) {
      console.error(error);
      // Handle error, e.g., show an error message to the user
    }
};



  
  

  
  if (!product) {
    return <div>Loading...</div>;
  }




  return (
    <>
      <Card className="product-card">
        <Card.Body>
          <Card.Title>MBWay Checkout</Card.Title>
          <Card.Subtitle className="mb-2">{product.title} {product.number} - {product.capacity}Kg</Card.Subtitle>
          <Card.Text>€{product.price * cycles}</Card.Text>

          <Form onSubmit={handleSubmit}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <Form.Group controlId="phone">
                  <Form.Label>Phone Number (obrigatório):</Form.Label>
                  <PhoneInput
                      international
                      placeholder="Introduza o seu número de telefone"
                      defaultCountry="PT"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      className="phone-input"
                      required
                      />
                </Form.Group>
              </Col>
            </Row>

            {locationDefinitionData && locationDefinitionData.office_gest_flag === 1 && (
              <Row style={{ marginBottom: '1rem' }}>
                <Col>
                  <Form.Group controlId="nif">
                  <Form.Label>NIF (opcional para fatura)</Form.Label>
                  <Form.Control
                    type="text"
                    value={nif}
                    onChange={(e) => setNif(e.target.value)}
                    onBlur={() => setIsValidNif(validateNif(nif))}
                  />
                  {nif && !isValidNif && (
                    <Form.Text className="text-danger">
                      Introduza um NIF válido.
                    </Form.Text>
                  )}
                </Form.Group>
                </Col>
              </Row>
            )}

            {locationDefinitionData && locationDefinitionData.office_gest_flag === 1 && (
              <Row style={{ marginBottom: '1rem' }}>
                <Col>
                <Form.Group controlId="email">
                  <Form.Label>Email (opcional para fatura)</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {email && !isValidEmail && (
                    <Form.Text className="text-danger">
                      Please enter a valid email address.
                    </Form.Text>
                  )}
                </Form.Group>
                </Col>
              </Row>
            )}

            {locationDefinitionData && locationDefinitionData.office_gest_flag === 1 && (
              <Row style={{ marginBottom: '1rem' }}>
                <Col>
                  <Form.Group controlId="name">
                    <Form.Label>Nome (opcional para fatura):</Form.Label>
                    <Form.Control
                      type="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Button
              variant="primary"
              type="submit"
              className="mt-3"
              disabled={
                !isValidPhoneNumber ||
                (nif && !isValidNif) ||
                (email && !isValidEmail)
              }>Finalizar
            </Button>
          </Form>
        </Card.Body>
      </Card>
      {/* Modal */}
      {showModal && (
        <CheckoutMbWayModal
          showModal={showModal}
          handleCloseModal={() => {
            setShowModal(false);
            if (machineActivated) {
              navigate('/');
            }
          }}
          handleCloseModalAndNavigate={() => {
            setShowModal(false);
            navigate(`/location/${locationId}`);
          }}
          paymentRequested={paymentRequested}
          paymentConfirmed={paymentConfirmed}
          paymentFailed={paymentFailed}
          machineActivated={machineActivated}
          paymentStatus={paymentStatus}
        />
      )}
    </>
  );
}

export default CheckoutMbWay;
