/*
 * Filename: application/frontend/src/App.js
 * Description: Main application component for the frontend.
 *              This component sets up routing and global state providers.
 * Author: Aires Marques
 * History:
 *  - 2023-11-04: Refactored ping logic and included seasonal theming.
 * Notes:
 *  - The application dynamically applies themes based on the season.
 *  - Ping endpoints are checked at the start to ensure backend services are available.
 */


// themes
import React, { useEffect } from 'react';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


import { backend } from './authConfig';

// Pages
import Cancel from './pages/Cancel';
import Store from './pages/Store';
import Success from './pages/Success';
import LocationChooser from './pages/LocationChooser';

// Contexts
import { fetchConfig } from './getConfig';

import { AppConfigProvider } from './contexts/AppConfigContext';
import { useAppConfig } from './contexts/AppConfigContext';

import { AppStateProvider } from './contexts/AppStateContext';
import { LocationProvider } from './contexts/LocationContext';

// Components
import DryOptions from './components/DryOptions';
import PaymentMethodOptions from './components/PaymentMethodOptions';
import CheckoutMbWay from './components/CheckoutMbWay';
import CheckoutStripe from './components/CheckoutStripe';
import StripeCompletion from './components/StripeCompletion';
import CustomerInfoRegistration from './components/CustomerInfoRegistration';

// Custom hooks



const date = new Date();
const isHalloweenSeason = (date.getMonth() === 9 && ((date.getDate() >=29 && date.getDate() <=31)));

if(isHalloweenSeason) {
  //require('./themes/halloween.css');
  console.log('Halloween');
} else {
  require('./themes/standard.css');
}


const AppUnavailableMessage = () => (
  <div className="unavailable-container">
      <img src="/outoforder.webp" alt="Out of Order" />
      <h1>Desculpe, a nossa app está temporariamente indisponivel.</h1>
      <h2>Iremos estar em linha quando estivermos em linha.</h2>
      <br/><br/>

      <h1>Sorry, our app is temporarily unavailable.</h1>
      <h2>We will be back online when we are back online.</h2>
  </div>
);

const MachineUnavailableMessage = () => (
  <div className="unavailable-container">
      <img src="/outoforder.webp" alt="Out of Order" />
      <h1>Desculpe, esta máquina está temporariamente indisponivel.</h1>
      <h2>Voltará a funcionar quando estiver a funcionar.</h2>
      <br/><br/>

      <h1>Sorry, this machine is temporarily out of order.</h1>
      <h2>It will be working again when it's working.</h2>
  </div>
);


const SorryWeAreClosing = () => (
  <div className="sorry-we-are-closing-container">
      <h1>Desculpe, vamos fechar.</h1>
      <h2>Amanhã de manhã voltamos a lavar e secar à grande.</h2>
      <br/><br/>

      <h1>Sorry, we are closing.</h1>
      <h2>Tomorrow morning we will be washing and drying like a boss again.</h2>
  </div>
);


// New component for handling redirection
const RedirectToAppropriateCheckout = ({ locationId, productNumber }) => {
  const { productData, isLoading } = useAppConfig();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // old code that uses the productNumber as an index - does not work for Frade
  //const product = productData[locationId][productNumber-1];

  // Find the product by productNumber instead of using it as an index
  const product = productData[locationId].find(p => p.machineId === productNumber);


  const isEnabled = product.state === 'on';
  if (!isEnabled) {
    return (
      <MachineUnavailableMessage />
  );  }

  return product && product.type === 'DRY'
    ? <Navigate to={`/dry-options/${locationId}/${productNumber}`} />
    : <Navigate to={`/location/${locationId}/pay-options/${productNumber}`} />;
};

const RedirectToAppropriateCheckoutWrapper = () => {
  const { locationId, productNumber } = useParams();
  return <RedirectToAppropriateCheckout locationId={locationId} productNumber={productNumber} />;
};



function App() {
  const [basicAppConfig, setBasicAppConfig] = useState(null);

  const fName = "App";
  const logPrefix = `<${fName}>`;
  console.log(`${logPrefix} backend.ping:`, backend.ping)

  useEffect(() => {
    async function loadConfig() {
        try {
            const config = await fetchConfig();
            setBasicAppConfig(config);
            console.log(`${logPrefix} config:`, config);
        } catch (error) {
            console.error("Error fetching app configuration:", error);
        }
    }
    loadConfig();
  }, [logPrefix]);

  const pingEndpoint = async (url, method = 'GET', headers = {}) => {
    try {
      const response = await fetch(url, { method, headers });
      if (!response.ok) {
        throw new Error(`Network response was not ok for ${url}`);
      }
      return await response.text();
    } catch (error) {
      console.error(`Error pinging ${url}:`, error);
      return null;
    }
  };

  
  useEffect(() => {
    const pingBackends = async () => {
      const urlsToPing = [
        { url: backend.ping },
        { url: backend.ping_get_aux_validate_email },
        { url: backend.ping_post_payrequest, method: 'POST', headers: { 'Content-Type': 'application/json' } },
        { url: backend.ping_get_payrequest },
        { url: backend.ping_post_payrequest_stripe, method: 'POST' },
        { url: backend.ping_get_payrequest_config_stripe },
        { url: backend.ping_post_chatbot, method: 'POST', headers: { 'Content-Type': 'application/json' } }
      ];
    
      const promises = urlsToPing.map((config) => pingEndpoint(config.url, config.method, config.headers));
    
      const results = await Promise.all(promises);
    
      results.forEach((result, index) => {
        if (result !== null) {
          console.log(`Response from ${urlsToPing[index].url}:`, result);
        }
      });
    };
    pingBackends();
  }, []);

  if (!basicAppConfig) {
    return <div>Loading...</div>;
  }

  if (basicAppConfig.REACT_APP_ON !== "1") {
      return (
          <AppUnavailableMessage />
      );
  }

  // if the time in Portugal is between 00:50 and 07:00, we are closing
  if (date.getHours() >= 1 && date.getHours() <= 7) {
    return (
      <SorryWeAreClosing />
    );
  }



  return (
    <div className={isHalloweenSeason ? 'halloween-theme' : 'standard'}>
    <AppConfigProvider>
       <AppStateProvider>
        <LocationProvider>
          <Container>
            <BrowserRouter>
            <Routes>
              <Route index element={<LocationChooser />} />
              <Route path="/store" element={<Store />} />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
              <Route path="/customer-registration" element={<CustomerInfoRegistration />} />
              
              {/*< />*/}
              <Route path="/location/:locationId" element={<Store />} />

              <Route path="/dry-options/:locationId/:productNumber" element={<DryOptions />} />"
              <Route path="/location/:locationId/pay-options/:productNumber" element={<PaymentMethodOptions />} />
              
              
              <Route path="/checkout-details-mbway/:locationId/:productNumber" element={<CheckoutMbWay />} />
              <Route path="/checkout-details-stripe/:locationId/:productNumber" element={<CheckoutStripe />} />
              <Route path="/completion" element={<StripeCompletion />} />

              {/* New route with dynamic redirect */}
              <Route
                path="/direct-purchase/:locationId/:productNumber"
                element={ <RedirectToAppropriateCheckoutWrapper /> }
              />


              {/* Legacy route with dynamic redirect */}
              {/* <Route
                path="/checkout-mbway/:locationId/:productNumber"
                element={ <RedirectToAppropriateCheckoutWrapper /> }
              /> */}

            </Routes>
            </BrowserRouter>
          </Container>
        </LocationProvider>
        </AppStateProvider> 
    </AppConfigProvider>
    </div>


  );
}

export default App;
