// application/frontend/src/components/CheckOutMbWayModal.jsx
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import '../themes/EllipsisAnimation.css';

const paymentStatusMapping = {
  "000": "Transação financeira concluída com sucesso",
  "020": "Transação financeira cancelada pelo utilizador",
  "023": "Transação financeira devolvida pelo Comerciante",
  "048": "Transação financeira cancelada pelo Comerciante",
  "100": "A operação não pôde ser concluída",
  "104": "Operação financeira não permitida",
  "111": "O formato do número de telemóvel não estava correto",
  "113": "O número de telemóvel usado como identificador não foi encontrado",
  "122": "Operação recusada ao utilizador",
  "123": "Transação financeira não encontrada",
  "125": "Operação recusada ao utilizador",
};


const CheckoutMbWayModal = ({
  showModal,
  handleCloseModal,
  handleCloseModalAndNavigate,
  paymentRequested,
  paymentConfirmed,
  paymentFailed,
  machineActivated,
  paymentStatus
}) => {

  const handleOkButtonClick = () => {
    handleCloseModalAndNavigate();
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Checkout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Verifique que a máquina está disponível.</h5>
        <h5>Verifique que a porta da máquina está aberta.</h5>
        <br />
        <h5>Aprove o pagamento na App MB WAY</h5>


        <h5 className={paymentRequested ? 'd-block' : 'd-none'}>
          Aguardando a confirmação do pagamento{' '}
          <span className={!paymentConfirmed && !paymentFailed ? 'ellipsis' : ''}></span>
          {paymentConfirmed && (
            <>
              <FontAwesomeIcon icon={faCheck} style={{ color: 'green', marginLeft: '8px' }} />
              <p>{paymentStatusMapping[paymentStatus]}</p>
            </>
          )}
        </h5>
  
        {/* <h5 className={paymentConfirmed ? 'd-block' : 'd-none'}>
          Aguardando a ativação da máquina{' '}
          <span className={!machineActivated && !paymentFailed ? 'ellipsis' : ''}></span>
          {machineActivated && <FontAwesomeIcon icon={faCheck} style={{ color: 'green', marginLeft: '8px' }} />}
        </h5> */}
  
        <h5 className={paymentFailed ? 'd-block' : 'd-none'}>
          O pagamento falhou ou expirou
          <p>{paymentStatusMapping[paymentStatus]}</p>
        </h5>
  
        <h5 className={machineActivated ? 'd-block' : 'd-none'}>
          A máquina será ativada brevemente.<br/><br/>
          <strong>Para iniciar, escolha o programa e pressione Start</strong><br/>
        </h5>

      </Modal.Body>
      {machineActivated && (
        <Modal.Footer>
          <Button variant="primary" onClick={handleOkButtonClick}>
            OK
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CheckoutMbWayModal;
