
/*
 * Filename: application/frontend/src/hooks/usePaymentProcessing.js
 * Description: CheckoutMbWay component
 * Author: Aires Marques
 * History:
 + 2023-10-22 - Checkout MbWay documented
 * Notes:
*/


import { useState } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';

import { backend } from '../authConfig';
import { useAppState } from '../contexts/AppStateContext';

// You may need to import other dependencies based on your actual implementation
// such as 'backend' and 'fetch'

export const usePaymentProcessing = ({ locationId, productNumber, phoneNumber, nif, email, name}) => {
  const { cycles } = useAppState();
  const { sessionUUID } = useAppState();

  const [paymentRequested, setPaymentRequested] = useState(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [machineActivated, setMachineActivated] = useState(false);
  
  // ... (other state variables or effects)

  /**
   * submitPaymentRequest: Sends the MBWay payment request to the server with the new API structure.
   *
   * @returns {Promise<string>} - A promise that resolves to the payment ID.
   * @throws {Error} - If the payment request fails.
   */
  const submitPaymentRequest = async () => {
    const formattedLocationId = locationId.toString().padStart(2, '0');
    const formattedProductNumber = productNumber.toString().padStart(2, '0');
  
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    const countryCode = parsedPhoneNumber.countryCallingCode;
    const nationalNumber = parsedPhoneNumber.nationalNumber;
    const formattedPhoneNumber = `${countryCode}#${nationalNumber}`;
    console.log('Formatted phone number:', formattedPhoneNumber);

    const params = {
      phone_number: formattedPhoneNumber,
      email: email,
      nif: nif,
      name: name,
      session_uuid: sessionUUID,
      machines: [
        {
          laundry_id: formattedLocationId,
          machine_id: formattedProductNumber,
          number_of_impulses: cycles
        }
      ]
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)
    };

    try {
      const response = await fetch(`${backend.url_post_payrequest}`, requestOptions);
      setPaymentRequested(true);

      if (!response.ok) {
        throw new Error('Failed to create MBWay payment');
      }
      const responseData = await response.json();
      const paymentId = responseData.IdPedido || responseData.payment_id;
      console.log('Payment ID:', paymentId);
      return paymentId;
    } catch (error) {
      console.error('Error:', error);
      setPaymentFailed(true);
    }
  };


  /**
   * pollPaymentConfirmation: Polls the server for the MBWay payment confirmation.
   *
   * @param {string} paymentId - The payment ID to check the confirmation for.
   */
  const pollPaymentConfirmation = async (paymentId) => {
    console.log("Polling payment confirmation for payment ID:", paymentId);
  
    const maxRetries = 12; // Poll for 2 minutes (12 times * 10 seconds)
    let retries = 0;
  
    const paymentStatusMapping = {
      "000": "Financial transaction completed successfully",
      "020": "Financial transaction cancelled by the user",
      "023": "Financial transaction returned by the Merchant",
      "048": "Financial transaction cancelled by the Merchant",
      "100": "The operation could not be completed",
      "104": "Financial operation not allowed",
      "111": "The format of the mobile number was not in the correct format",
      "113": "The mobile number used as an identifier was not found",
      "122": "Operation refused to the user",
      "123": "Financial transaction not found",
      "125": "Operation refused to the user",
    };
  
    const poll = async () => {
      if (retries >= maxRetries) {
        console.log("Polling stopped after reaching the maximum retries");
        setPaymentFailed(true);
        return;
      }
  
      try {
        const queryParams = new URLSearchParams({ payment_id: paymentId }).toString();
        const response = await fetch(`${backend.url}v3/payrequest/mbway?${queryParams}`);
  
        if (!response.ok) {
          throw new Error('Failed to poll MBWay payment');
        }
  
        const responseData = await response.json();
        const paymentStatus = responseData.Estado;
        console.log("responseData", responseData);
  
        if (paymentStatus !== "123") {
          if (paymentStatus === "000") {
            console.log("Payment status changed:", responseData);
            setPaymentConfirmed(true);
            pollMachineActivation();
          } else if (paymentStatusMapping[paymentStatus]) {
            console.log("Payment status changed:", paymentStatusMapping[paymentStatus]);
            setPaymentFailed(true);
          }
        } else {
          retries++;
          setTimeout(poll, 10000); // Poll every 10 seconds
        }
        setPaymentStatus(paymentStatus);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    poll();
  };

  /**
   * pollMachineActivation: Polls the server for the machine activation.
   */
  const pollMachineActivation = async () => {
    setTimeout(() => {
      setMachineActivated(true);
    }, 5000);
  };

  return {
    paymentRequested,
    setPaymentRequested,
    paymentConfirmed,
    setPaymentConfirmed,
    paymentFailed,
    setPaymentFailed,
    paymentStatus,
    setPaymentStatus,
    machineActivated,
    setMachineActivated,
    submitPaymentRequest,
    pollPaymentConfirmation,
    pollMachineActivation,
  };
};
